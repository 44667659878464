<template>
	<div>
		<!-- 公共头部 -->
		<Header ></Header>
		<div class="nolist" v-if="cateList.length==0&&newsList.length==0">
			<div class="img"><img :src="icon1" alt=""></div>
			<div class="text">暂无相关列表</div>
		</div>
		<div class="section1" v-if="cateList.length>0">
			<div :data-src="'/news?id='+item.id" class="item" :data-id="item.id" v-for="(item,index) in cateList" :class="[ id == item.id? 'active':'' ]" :key="index" @click="toggleCont">{{item.title}}</div>
	
			<!-- <router-link :to="'/news?id='+item.id" class="item " v-for="(item,index) in cateList" :class="[ $route.query.id == item.id ? 'active':'' ]" :key="index">{{item.title}}</router-link> -->
		</div>
		<div class="section2">
			<ul class="list">
				<router-link :to="'/newsInfo?id='+item.cate_id+'&aid='+item.id" class="item"  v-for="(item,index) in newsList" :key="index">
					<div class="pic" v-if="item.img">
						<img class="img" :src="item.img" />
					</div>
					<div class="content" :style="{ width: item.img? '4.4rem':'100%' }">
						<h2 class="title" :style="{ paddingRight: item.top == 1 ? '0.1rem':'0' }">{{item.title}}<span class="tab" v-if="item.top == 1">置顶</span></h2>
						<div class="desc">{{item.description}}</div>
						<div class="time">{{item.addtime}}<div class="num">{{item.num}}</div></div>
					</div>
				</router-link>
			</ul>
			<wd-pagination v-model="page" :total="total"></wd-pagination>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	import icon1 from '@/assets/images/icon108.png';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				icon1:icon1,
				title: '',
				cateList: [],
				page: 1,
				limit: 10,
				total: 0,
				id:0,
				type:'',
				newsList: []
			}
		},
		watch: {
			$route(to,form) {
				if((to.path == form.path) && (this.$route.path == '/news')){
					if(this.page == 1){
						this.id = this.$route.query.id;
						this.type = this.$route.query.type;
						this.getNewsList(this.id,this.type);
					} else {
						this.page = 1;
					}
				}
			},
			page() {
				if(this.$route.path == '/news'){
					this.getNewsList(this.id,this.type);
				}
			}
		},
		
		created() {
			this.id = this.$route.query.id
			this.type = this.$route.query.type
	      	 this.getCateList(this.type);
			this.getNewsList(this.id,this.type);
		},
		methods: {
			toggleCont(e){
				this.page = 1;
				this.limit = 10;
				let id = e.target.dataset.id||this.$route.query.id;
				this.id = id;
				let type = e.target.dataset.type||this.$route.query.type;
				this.type = type;
				this.getNewsList(id,type)

			},
			async getCateList(types) {
				this.$toast.loading('加载中...');
				var type = types || 0;
					var data = {
					type
				}
				const { data:res } = await this.$http.get('/article/newsCate',{ params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('学校动态公用cate：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					console.log(this.cateList.length);
					if(type!=3){
					this.cateList = res.data;
					this.id = this.cateList[0].id;
					this.getNewsList(this.id,type)
					}else{
					this.cateList = []
					this.getNewsList(this.id,type)
					}
					
				} else {
					this.cateList = [];
				}
			},
			async getNewsList(id,type) {
				var cateId = id || 0;
				var page = this.page;
				var limit = this.limit;
					var data = {
					cateId, page, limit,type
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/newsList', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('学校动态列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var total = res.data.count;
					this.total = total;
					var newsList = res.data.lists;
					for(var i = 0; i < newsList.length; i++) {
						newsList[i]['addtime'] = this.utils.js_date_time(newsList[i]['addtime'],1);
						if(newsList[i]['img'] && newsList[i]['img'].substring(0,4) != 'http'){
							newsList[i]['img']=this.$store.state.http + newsList[i]['img']
						}
					}
					this.newsList = newsList;
				} else {
					this.newsList = [];
				}
			}
		},
	};
</script>

<style scoped>
	.section1{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #F3F3F3;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .item{
		
		font-size: 0.28rem;
		color: #969897;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.8rem;
			width: 30%;
	}
	.section1 .item.active{
		font-size: 0.3rem;
		color: #FFFFFF;
		background: #86cb08;
		position: relative;
	}
	/* .section1 .item.active::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.48rem;
		height: 0.04rem;
		background: #ff6000;
		border-radius: 0.02rem;
		bottom: 0.08rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
	} */
	.section2{
		margin-top: 0.3rem;
		margin-bottom: 0.8rem;
	}
	.section2 .cate-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1.04rem;
		padding: 0 0.3rem;
	}
	.section2 .cate-box .cate-list{
		display: flex;
		align-items: center;
	}
	.section2 .cate-box .cate-list .item{
		font-size: 0.28rem;
		color: #888888;
		margin-right: 0.45rem;
		height: 0.66rem;
		display: flex;
		align-items: center;
	
	}
	.section2 .cate-box .cate-list .item:last-child{
		margin-right: 0;
	}
	.section2 .cate-box .cate-list .item.active{
		font-size: 0.32rem;
		color: #333333;
		position: relative;
	}
	.section2 .cate-box .cate-list .item.active::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.48rem;
		height: 0.04rem;
		background: #ff6000;
		bottom: 0;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
	}
	.section2 .cate-box .more{
		font-size: 0.28rem;
		color: #333333;
	}
	.section2 .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		margin-bottom: 0.3rem;
	}
	.section2 .list .item{
		padding: .3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 0.02rem solid #e3e3e3;
		
	}
	.section2 .list .item:first-child{
		border-top: none;
	}
	.section2 .list .item .pic{
		width: 2.3rem;
		height: 1.6rem;
	}
	.section2 .list .item .pic .img{
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.section2 .list .item .content{
		width: 4.4rem;
		height: 1.6rem;
	}
	.section2 .list .item .content .title{
		font-size: 0.32rem;
		color: #333333;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 0.6rem;
		position: relative;
		line-height: 0.4rem;
	}
	.section2 .list .item .content .title .tab{
		font-size: 0.2rem;
		color: #ff6000;
		position: absolute;
		right: 0;
		border: 0.02rem solid #ff6000;
		border-radius: 0.04rem;
		height: 0.4rem;
		line-height: 0.38rem;
		padding: 0 0.04rem;
	}
	.section2 .list .item .content .desc{
		font-size: 0.24rem;
		color: #969897;
		line-height: 0.4rem;
		height: 0.8rem;
		overflow: hidden;
		
	}
	.section2 .list .item .content .time{
		font-size: 0.24rem;
		color: #969897;
		height: 0.4rem;
		line-height: 0.4rem;
		position: relative;
	}
	.section2 .list .item .content .num{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
		padding-left: 0.3rem;
		background: url(../../assets/images/icon15.png) no-repeat left center;
		background-size: auto 0.16rem;
	}
	.nolist{
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%,-50%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #ccc;
	}
	.nolist img{
		margin-bottom: .2rem;
	}
</style>